import React from 'react';
import * as style from "../../../styles/subblock.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PageHeaderLeftItem from "./page-header-left-item";

const PageHeader = ({header}) => {
  return (
    <>
        {/* header background picture */}
        <div className={style.pageHeaderLayerImage}>
          <GatsbyImage
            image={getImage(header.titlePicture.localFile)}
            alt={header.titlePicture.alternativeText}
            className={style.pageHeaderImage}
          />
        </div>

        {/* header right side */}
        {header.productPicture && (
          <div className={style.pageHeaderLayerProduct}>
            <GatsbyImage
              image={getImage(header.productPicture.localFile)}
              alt={header.productPicture.alternativeText}
              className={style.pageHeaderImageProduct}
              imgStyle={{objectFit:"contain"}}
            />
          </div>
        )}

        {/*header left side*/}
        <div className={style.pageHeaderLayerContent}>
          <div className={"global-left p-5 "}>
            <PageHeaderLeftItem header={header} />
          </div>
        </div>
    </>
  );
};

export default PageHeader;