import React from 'react';


import SubblockWrapper from "./subblock-wrapper";
import ArrowList from "../ArrowList";

const SubblockList =({list, listTitle, fullwidth, download}) => {

  // if list ist empty then nothing to display
  if (list?.length === 0) return <></>

  return (
    <>
      <SubblockWrapper fullwidth={fullwidth} colorTheme={"dark"} position="center" >
        <ArrowList header={listTitle} items={list || []} download={download}/>
      </SubblockWrapper>
    </>
  );
}

export default SubblockList;