import React from 'react';

// add downloadlink if download file exists
const DowloadLink = ({item,children}) => {
  const link = item.download?.localFile?.publicURL
  return (
    <>
      {link ? (
        <a href={link} className="text-decoration-none" target="_blank" rel="noreferrer" >
          {children}
        </a>
      ):(
        <>{children}</>
      )}
    </>
    )
}

// items : [{title: String, subTitle? : String, item.download?.localFile?.publicURL : String}]
const ArrowList =({header,items,itemClassName, download, underline}) => {
  const ulClassName = download ? "arrow-list-bullet " : "";
  const underlineClass = underline ? " text-decoration-underline " : "";

  return (
    <>
      {header && (<h3 className="h2 text-start" style={{hyphens: "auto"}} >{header}</h3>)}
      <ul className={ulClassName+ " text-primary"}>
        {items.map((item,index) =>(
          <li className={"mb-3 mb-0 "+itemClassName} key={index}>
            <DowloadLink item={item}>
              <p className={underlineClass + " h5 text-start text-white m-0"}>{item.title}</p>
            </DowloadLink>
            <p className="text-white" dangerouslySetInnerHTML={{__html: item.subTitle }}></p>
          </li>
        )) }
      </ul>
    </>
  );
}

export default ArrowList;