import React from 'react';
import SectionLayout from "../layout/sectionLayout";
import PageHeader from "../page-header/page-header";

const SubblockPageHeader = ({content}) => {

  return (
    <>
      <SectionLayout>
        <PageHeader header={content}/>
      </SectionLayout>
    </>
  );
};

export default SubblockPageHeader;