import React from 'react';

import SubblockWrapper from "./subblock-wrapper";
import {Col, Row} from "react-bootstrap";

const SubblockHeadlineWithSubtitle =({title, title2, subTitle, textCenter, noBottom, setAsH1 = false}) => {

  const titleClass = textCenter ? "text-center" : "";
  const subTitleClass = "pt-0 font-helvetica-light "+ (textCenter ? "text-center" : "");

  return (
    <>
      <SubblockWrapper position="center" noBottom={noBottom} fullwidth>
        <Row className={"my-3"}>
          <Col xs={12} className={titleClass}>
            { setAsH1 && (<h1 className="m-0">{title}</h1>) }
            { !setAsH1 && (<h2 className="h1 m-0">{title}</h2>) }
        </Col>
          {title2 && (
            <Col xs={12} className={titleClass}>
              <p className={"h1 m-0"}>{title2}</p>
            </Col>
          )}
          {subTitle && (
            <Col xs={12}>
              <p className={subTitleClass}>{subTitle}</p>
              { setAsH1 && (<h2 className="hidden-but-visible-for-seo">{subTitle}</h2>) }
            </Col>)
          }
        </Row>
      </SubblockWrapper>
    </>
  );
}

export default SubblockHeadlineWithSubtitle;
